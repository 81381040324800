import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { MediaType, Organization, StorageType } from "@interfaces/organization"
import { useAuthAxios } from "./axiosInstance"
import { FilterParams } from "@components/Home/interface"
import { Sort } from "@interfaces/default"
import { ChannelRequest } from "@components/Nav/RequestChannelDialog"
import { RubyEnum, RubyEnums } from "./interfaces"

export const useFetchOrganizations = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["organizations"], async () => {
    const response = await (await axiosInstance).get<{ organizations: Organization[] }>("/organizations")

    return response.data
  })
}

export const useFetchOrganizationsAdmin = (options?: {
  page?: number
  filterParams?: any
  all?: boolean
  userOrganizationId?: number | string
  archiveType?: string
  archiveStatusFilter?: string
  nameFilter?: string
  spamEnabledFilter?: string
  autoUpdate?: string
  channelTypeFilter?: string
  videoArchiveIdFilter?: string
  proxyShuffleFilter?: string
  oppAnalysisEnabledFilter?: string
  sortBy?: Sort
}) => {
  const axiosInstance = useAuthAxios()
  const {
    page,
    filterParams,
    all,
    userOrganizationId,
    archiveType,
    nameFilter,
    spamEnabledFilter,
    autoUpdate,
    channelTypeFilter,
    archiveStatusFilter,
    videoArchiveIdFilter,
    proxyShuffleFilter,
    oppAnalysisEnabledFilter,
    sortBy,
  } = options || {}

  const filterParamsExtended = { ...filterParams, sort_by: sortBy }

  if (all) {
    return useQuery(["organizations", "all"], async () => {
      const response = await (await axiosInstance).get("/admin/organizations?page=all")
      return response.data
    })
  } else if (userOrganizationId !== undefined) {
    return useQuery(["organizations", { user_organization_id: userOrganizationId.toString() }], async () => {
      const response = await (
        await axiosInstance
      ).get(`/admin/organizations?user_organization_id=${userOrganizationId}`)
      return response.data
    })
  } else {
    return useQuery(
      [
        "organizations",
        {
          page,
          filterParamsExtended,
          archiveType,
          nameFilter,
          spamEnabledFilter,
          autoUpdate,
          channelTypeFilter,
          archiveStatusFilter,
          videoArchiveIdFilter,
          proxyShuffleFilter,
          oppAnalysisEnabledFilter,
        },
      ],
      async () => {
        const encodedFilterParams = btoa(JSON.stringify(filterParamsExtended))
        const response = await (
          await axiosInstance
        ).get(
          `/admin/organizations?page=${page}&filter_params=${encodedFilterParams}&` +
            `video_archive_type=${archiveType}&name_filter=${nameFilter}&spam_enabled=${spamEnabledFilter}&` +
            `auto_update=${autoUpdate}&channel_type=${channelTypeFilter}&archive_status=${archiveStatusFilter}` +
            `&domain=${videoArchiveIdFilter}&shuffle_region=${proxyShuffleFilter}` +
            `&opp_analysis_enabled=${oppAnalysisEnabledFilter}`,
        )
        return response.data
      },
    )
  }
}

export const useFetchOrganization = (id: string | number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["organizations", { id: id.toString() }], async () => {
    // adding the organization type to this breaks /pages/organizationDetailPage/InfoSection.tsx
    const response = await (await axiosInstance).get(`/organizations/${id}`)

    return response.data
  })
}

export const useNewOrganization = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutation(
    async (params?: any) => {
      return (await axiosInstance).post("/admin/organizations/", params)
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("organizations")
        history.push(`/admin/organizations/${response.data?.organization?.id}`)
      },
    },
  )
}

export const useCopyOrganization = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutation(
    async (params?: any) => {
      return (await axiosInstance).post(`/admin/organizations/${params.orgId}/copy`)
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("organizations")
        history.push(`/admin/organizations/${response.data?.organization?.id}`)
      },
    },
  )
}

export const useExportAsCsv = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post("/admin/organizations/export_as_csv", {
        org_ids: params?.orgIds,
      })
    },
    {
      onSuccess: (response: any) => {
        const file = new Blob([response.data], { type: "text/csv;charset=utf-8;" })
        const a = document.createElement("a")
        const tempUrl = URL.createObjectURL(file)
        a.href = tempUrl
        a.download = "org_list.csv"
        document.body.appendChild(a)
        a.click()
        setTimeout(function () {
          document.body.removeChild(a)
          window.URL.revokeObjectURL("org_list.csv")
        }, 0)
      },
    },
  )
}

export const useDeleteOrganizations = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(`/admin/organizations/${params.orgIds.join(",")}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organizations")
        queryClient.invalidateQueries("state_permissions")
        queryClient.invalidateQueries("permissions")
      },
    },
  )
}

interface OrganizationEnums extends RubyEnums {
  download_service: RubyEnum[]
  video_archive_type: RubyEnum[]
  archive_status: RubyEnum[]
  storage_persistence_type: RubyEnum<StorageType>[]
  media_type: RubyEnum<MediaType>[]
}

export const useFetchOrganizationMetadata = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["organization_metadata"], async () => {
    const response = await (await axiosInstance).get<OrganizationEnums>("/admin/organizations/metadata")

    return response.data
  })
}

export const useUploadOrgsFromCsv = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ file }: { file: File }) => {
      const formData = new FormData()

      // Update the formData object
      formData.append("org_list", file)

      return (await axiosInstance).post("/admin/organizations/upload_via_csv", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organizations")
        queryClient.invalidateQueries("geographies")
        queryClient.invalidateQueries("permissions")
        queryClient.invalidateQueries("state_permissions")
      },
    },
  )
}

export const useUpdateOrgsFromCsv = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ file }: { file: File }) => {
      const formData = new FormData()
      // Update the formData object
      formData.append("org_list", file)

      return (await axiosInstance).post("/admin/organizations/update_via_csv", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organizations")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useFetchMeetingsByTitle = (id: string, title: string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings_by_title", id, title],
    async () => {
      const response = await (await axiosInstance).get(`/organizations/${id}/meetings_by_title?title=${title}`)

      return response.data
    },
    {
      enabled: id !== undefined && id !== null,
    },
  )
}

export const useFetchChannelDirectory = (
  page: number,
  filterParams: FilterParams,
  channelName: string,
  sortBy: Sort,
) => {
  const axiosInstance = useAuthAxios()
  const filterParamsAdded = { ...filterParams, channel_name: channelName, sort_by: sortBy }

  return useQuery(["channel_directory", page, filterParamsAdded, channelName], async () => {
    const formatParams = btoa(JSON.stringify(filterParamsAdded))
    const response = await (
      await axiosInstance
    ).get(`/organizations/channel_directory?page=${page}&filter_params=${formatParams}`)

    return response.data
  })
}

export const useSendRequestEmail = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (channels: ChannelRequest[]) => {
    return (await axiosInstance).post("/organizations/send_request_email", { channels })
  })
}
