import { FC, useState, useEffect } from "react"
import {
  Button,
  Card,
  CardContent,
  Dialog,
  Stack,
  DialogContent,
  DialogTitle,
  Fab,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material"
import { marked } from "marked"
import { sanitize } from "dompurify"
import { useFetchCurrentUser, useFetchUsersMap } from "@api/users"
import {
  useFetchMeetingBriefings,
  useFetchMeeting,
  useFetchMeetingBriefingCreditUsagesMap,
  useCreatePersonalBriefing,
} from "@api/meetings"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useCreatePDFAndDownload } from "../Shared/BriefingPDF"
import { getPrompts } from "../../../api/prompts"
import { BriefingCard } from "@stories/BriefingCard"
import { PersonalBriefingCreation } from "./PersonalBriefingCreation"
import { useToast } from "../../shared/ToastHook"
import AddIcon from "@mui/icons-material/Add"
import { BriefingContent } from "../Shared/BriefingContent"
import { PublicBriefing } from "@src/interfaces/briefing"
import { useRetryBriefing } from "@api/briefings"

function updateMapWithMostRecentBriefing(briefingMap: Map<string, PublicBriefing>, briefing: PublicBriefing) {
  const briefingWithSameName = briefingMap.get(briefing.name)
  if (
    !briefingWithSameName ||
    new Date(briefing.updated_at).valueOf() > new Date(briefingWithSameName.updated_at).valueOf()
  ) {
    briefingMap.set(briefing.name, briefing)
  }
}

const BriefingCardWithRetry: FC<{
  briefing: PublicBriefing
  ownerIds?: number[]
}> = ({ briefing, ownerIds }) => {
  const { data: usersMap } = useFetchUsersMap()
  const { mutate: retryBriefing, isLoading: retryIsLoading } = useRetryBriefing(briefing.id, briefing.meeting_id)

  return (
    <BriefingCard
      briefing={briefing}
      isLoading={retryIsLoading}
      onRetryBriefing={retryBriefing}
      ownerEmails={ownerIds?.map((id) => usersMap?.get(id)?.email || "").filter((email) => email)}
    />
  )
}

export const PersonalBriefing: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { mutate: createPersonalBriefing, isLoading: briefingIsLoading } = useCreatePersonalBriefing(meetingId)
  const { data: currentUser } = useFetchCurrentUser()
  const { data: fetchedMeeting } = useFetchMeeting(meetingId)
  const { data: briefingCreditUsagesMap } = useFetchMeetingBriefingCreditUsagesMap(meetingId)
  const { data: unfilteredBriefings, refetch } = useFetchMeetingBriefings(meetingId, {
    onSuccess: (briefingData) => {
      if (
        briefingData.some((briefing) => {
          if (!briefing.result) {
            const createTime = new Date(briefing.created_at)
            if (Date.now() - createTime.valueOf() < 120000) {
              // briefing was created less than 2 minutes ago so possibly still processing
              return true
            }
          }
          return false
        })
      ) {
        setTimeout(refetch, 10000) // check again in 10 seconds
      }
    },
  })
  const { data: prompts, isFetched: promptsIsFetched } = getPrompts("personal")
  const toast = useToast()
  const [selectedPrompts, setSelectedPrompts] = useState<Set<number>>(new Set())
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const close = () => setIsDialogOpen(false)

  useEffect(() => {
    if (prompts && promptsIsFetched && selectedPrompts.size === 0) {
      setSelectedPrompts(new Set([prompts[0].id]))
    }
  }, [promptsIsFetched])

  const personalizedBriefings = unfilteredBriefings?.filter(({ is_personalized }) => is_personalized) || []

  // this is backwards compatibility stuff
  // briefings in the new format have a name
  const newBriefings = personalizedBriefings?.filter(({ name }) => name) || []

  const briefings: PublicBriefing[] = []
  let briefingContent: JSX.Element = <></>
  if (newBriefings.length) {
    if (briefingCreditUsagesMap && currentUser) {
      // if this isn't ready don't show anything
      const ownBriefingsMap = new Map<string, PublicBriefing>()
      const othersBriefingsMap = new Map<string, PublicBriefing>()
      newBriefings.forEach((briefing) => {
        const ownerIds = briefingCreditUsagesMap
          .get(briefing.id)
          ?.map((briefingCreditUsage) => briefingCreditUsage.user_id)

        if (ownerIds) {
          if (ownerIds.includes(currentUser.id)) {
            updateMapWithMostRecentBriefing(ownBriefingsMap, briefing)
          } else {
            updateMapWithMostRecentBriefing(othersBriefingsMap, briefing)
          }
        }
      })
      briefings.push(...ownBriefingsMap.values(), ...othersBriefingsMap.values())
      briefingContent = (
        <>
          {ownBriefingsMap.size > 0 && <Typography variant="h6">My Briefings</Typography>}
          {Array.from(ownBriefingsMap.values()).map((briefing) => (
            <BriefingCardWithRetry
              key={briefing.id}
              briefing={briefing}
              ownerIds={briefingCreditUsagesMap
                .get(briefing.id)
                ?.map((briefingCreditUsage) => briefingCreditUsage.user_id)}
            />
          ))}
          {ownBriefingsMap.size > 0 && othersBriefingsMap.size > 0 && <Divider />}
          {othersBriefingsMap.size > 0 && <Typography variant="h6">All Briefings</Typography>}
          {Array.from(othersBriefingsMap.values()).map((briefing) => (
            <BriefingCardWithRetry
              key={briefing.id}
              briefing={briefing}
              ownerIds={briefingCreditUsagesMap
                .get(briefing.id)
                ?.map((briefingCreditUsage) => briefingCreditUsage.user_id)}
            />
          ))}
        </>
      )
    }
  } else if (personalizedBriefings && personalizedBriefings.length) {
    // we only have old format briefings so we should select the newest briefing
    let newestBriefing = personalizedBriefings[0]
    for (let i = 1; i < personalizedBriefings.length; i++) {
      if (personalizedBriefings[i].is_personalized && personalizedBriefings[i].created_at > newestBriefing.created_at) {
        newestBriefing = personalizedBriefings[i]
      }
    }
    briefings.push(newestBriefing)
    briefingContent = <BriefingContent promptResult={newestBriefing.result} />
  }

  const createPDFAndDownload = useCreatePDFAndDownload(
    <div
      dangerouslySetInnerHTML={{
        __html: sanitize(
          marked.parse(
            briefings.map(({ name, result }) => `### ${name}\n\n${result}`).join("\n\n---\n\n") || "",
          ) as string,
        ),
      }}
    />,
    fetchedMeeting?.meeting,
  )

  if (personalizedBriefings && personalizedBriefings.length > 0) {
    return (
      <>
        <Stack spacing={1}>
          <Stack direction="row">
            <Stack direction="row" spacing={1} flex="1">
              <Button variant="contained" onClick={createPDFAndDownload}>
                Full Briefing PDF
              </Button>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    briefings.map(({ name, result }) => `### ${name}\n\n${result}`).join("\n\n"),
                  )
                  toast("Full Briefing Copied to clipboard")
                }}
              >
                Copy Full Briefing to Clipboard
              </Button>
            </Stack>
            {briefingIsLoading ? (
              <CircularProgress />
            ) : (
              <Fab size="small" color="primary" onClick={() => setIsDialogOpen(true)}>
                <AddIcon />
              </Fab>
            )}
          </Stack>
          {briefingContent}
        </Stack>
        <Dialog open={isDialogOpen} onClose={close}>
          <DialogTitle>Create Personal Briefing</DialogTitle>
          <DialogContent>
            <PersonalBriefingCreation
              onConfirmCreatePrompt={createPersonalBriefing}
              blockedPrompts={briefings.map(({ name }) => name)}
              onSuccess={() => {
                close()
              }}
              isLoading={briefingIsLoading}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  } else {
    return (
      <Card>
        <CardContent>
          <PersonalBriefingCreation onConfirmCreatePrompt={createPersonalBriefing} isLoading={briefingIsLoading} />
        </CardContent>
      </Card>
    )
  }
}
