import { DataGrid, GridPaginationModel, GridRowSelectionModel, GridSortModel } from "@mui/x-data-grid"
import { FC, useContext, useMemo, useState } from "react"
import { getAdminBriefings } from "@api/admin/briefings"
import { adminBriefingColumns } from "@src/dataGridSchema/briefings/briefingColumns"
import { adminBriefingRows } from "@src/dataGridSchema/briefings/briefingRows"
import { BriefingsContainerContext } from "@src/containers/BriefingContainers/BriefingsContainer"
import { Toolbar } from "./Toolbar"

export const BriefingsAdminDataGrid: FC = () => {
  const { params, urlParamState, setUrlParamState } = useContext(BriefingsContainerContext)

  let paginationModel = {
    pageSize: urlParamState.count,
    page: urlParamState.page,
  }

  const setPaginationModel = (model: GridPaginationModel) => {
    paginationModel = model
  }

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const paramsWithOrder = new URLSearchParams(params)
  if (!paramsWithOrder.has("sort")) {
    paramsWithOrder.set("sort", "created")
    paramsWithOrder.set("order", "desc")
  }
  const { data, isLoading } = getAdminBriefings(paramsWithOrder)

  const columns = adminBriefingColumns()
  const rows = useMemo(() => {
    if (data?.briefings) {
      return data.briefings.map((briefing) => adminBriefingRows(briefing))
    }
    return []
  }, [data])

  const handlePaginationModelChange = (mode: GridPaginationModel) => {
    setPaginationModel(mode)
    setUrlParamState({
      ...urlParamState,
      page: mode.page,
    })
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setUrlParamState({
      ...urlParamState,
      sort: sortModel[0]?.field,
      order: sortModel[0]?.sort || "asc",
      page: 0,
    })
  }

  return (
    <DataGrid
      autoHeight
      checkboxSelection
      columns={columns}
      disableRowSelectionOnClick
      loading={isLoading}
      onPaginationModelChange={handlePaginationModelChange}
      onRowSelectionModelChange={(newSelection) => setRowSelectionModel(newSelection)}
      onSortModelChange={handleSortModelChange}
      paginationMode="server"
      paginationModel={paginationModel}
      pageSizeOptions={[25]}
      rows={rows}
      rowCount={data?.total ?? -1}
      rowHeight={100}
      rowSelectionModel={rowSelectionModel}
      slots={{ toolbar: Toolbar }}
      slotProps={{ toolbar: { rowSelectionModel, setRowSelectionModel } }}
      sortingMode="server"
    />
  )
}
