import React, { FC, ReactElement, useState } from "react"
import {
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  Stack,
  CardHeader,
} from "@mui/material"
import { PublicBriefing } from "@interfaces/briefing"
import { marked } from "marked"
import { sanitize } from "dompurify"
import { useToast } from "@components/shared/ToastHook"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ReplayIcon from "@mui/icons-material/Replay"

export const LoadingText: FC = () => (
  <Stack direction="row" spacing={2} alignItems="center">
    <CircularProgress />
    <Typography>Generating briefing...</Typography>
  </Stack>
)

export interface BriefingCardProps {
  /** The briefing data to display */
  briefing: Pick<PublicBriefing, "name" | "result" | "summary" | "updated_at">
  /** Code to run when you click the retry button */
  onRetryBriefing?: () => void
  /** Emails of people who own this briefing */
  ownerEmails?: string[]
  /** Is the data for this briefing loading? */
  isLoading?: boolean
}

/** Card for displaying briefing data to clients */
export const BriefingCard: FC<BriefingCardProps> = ({
  briefing: { name, result, summary, updated_at },
  ownerEmails,
  onRetryBriefing,
  isLoading,
}) => {
  const [isFull, setIsFull] = useState(false)
  const toast = useToast()

  let error = false

  let briefingContent: ReactElement
  if (isLoading) {
    briefingContent = <LoadingText />
  } else if (result || summary) {
    briefingContent = (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(marked.parse(isFull ? result : summary) as string),
        }}
      />
    )
  } else {
    const updatedDate = new Date(updated_at)
    if (Date.now() - updatedDate.valueOf() > 120000) {
      // 2 * 60 * 1000 = 2 minutes
      error = true
      briefingContent = (
        <Typography>The generation of this briefing has errored. Please contact Cloverleaf AI.</Typography>
      )
    } else {
      briefingContent = <LoadingText />
    }
  }

  let action = null
  if (error) {
    if (onRetryBriefing) {
      action = (
        <Button startIcon={<ReplayIcon />} variant="contained" onClick={onRetryBriefing}>
          Retry
        </Button>
      )
    }
  } else {
    action = (
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(isFull ? result : summary)
          toast("Copied to clipboard")
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    )
  }

  return (
    <Card>
      <CardHeader title={name} subheader={ownerEmails?.join(", ")} action={action} />
      <CardContent sx={{ paddingTop: 0 }}>
        <Typography>{briefingContent}</Typography>
      </CardContent>
      {(result || summary) && (
        <CardActions>
          <Button
            onClick={() => setIsFull((prevValue) => !prevValue)}
            startIcon={isFull ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            variant="outlined"
          >
            {isFull ? "Show short briefing section" : "Show full briefing section"}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}
